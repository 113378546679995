import React, { Fragment } from "react";

import { Meta } from "./homepage/meta";
import { Gallery } from "./homepage/gallery";
import { Sections } from "./homepage/sections";

export const Template = () => (
  <Fragment>
    <Meta />
    <Gallery />
    <Sections />
  </Fragment>
);

export default Template;
