import React from "react";

import { Sections as SharedSections, getProperty } from "@lachevaliniere/shared";

import { usePages, useStaticHomepage } from "hooks";

export const Sections = () => {
  const pages = usePages();
  const staticHomepage = useStaticHomepage();
  const sections = getProperty(staticHomepage, "data.sections") || [];

  return <SharedSections sections={sections} pages={pages} color="tertiary" noPaddingBottom={true} />;
};
